<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Task_type')">
                <el-select v-model.trim="formInline.taskType" size="15">
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                    v-for="item in taskList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Submission_time')">
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
          >
            <template slot-scope="scope">
              <p>{{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="90">
            <template slot-scope="scope">
              <el-dropdown
                style="color: #20a0ff; cursor: pointer"
                @command="handleCommand($event, scope.row)"
              >
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="scope.row.taskStatus == '3'" command="1"
                    >审核</el-dropdown-item
                  >
                  <el-dropdown-item command="2">查看</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import timeRangePick from "@/components/timePicker";
import { setIndex } from "@/common/js/public.js";
import { getBeforeDate } from "@/common/js/utils";
export default {
  data() {
    const examineList = [
      {
        label: "全部",
        value: "",
      },
      {
        label: "待审核",
        value: "1",
      },
      {
        label: "已通过",
        value: "2",
      },
      {
        label: "已驳回",
        value: "3",
      },
      {
        label: "已超时",
        value: "4",
      },
    ];
    const taskList = [
      {
        label: "全部",
        value: "",
      },
      {
        label: "短信",
        value: "1",
      },
      {
        label: "电话",
        value: "2",
      },
    ];
    return {
      defalutDate: [],
      formInline: {
        taskType: "",
        taskTimeStart: "",
        taskTimeEnd: "",
      },
      examineList: examineList,
      taskList: taskList,
      loading: false,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      tableData: [],
      tableCols: [
        {
          prop: "recoverCode",
          label: "追缴编号",
          width: "",
        },
        {
          prop: "taskManagerName",
          label: "追缴负责人",
          width: "",
        },
        {
          prop: "taskStatusDesc",
          label: "任务状态",
          width: "",
          // formatter: (row, column) => {
          //   if (!row.taskStatus) return ''
          //   const taskStr = examineList.filter(item => {
          //     return item.value == row.taskStatus
          //   })
          //   return taskStr[0].label
          // }
        },
        {
          prop: "taskTime",
          label: "提交时间",
          width: "",
        },
        {
          prop: "taskTypeDesc",
          label: "任务类型",
          width: "",
          // formatter: (row, column) => {
          //   if (!row.taskStatus) return ''
          //   const taskStr = taskList.filter(item => {
          //     return item.value == row.taskStatus
          //   })
          //   return taskStr[0].label
          // }
        },
        {
          prop: "recoverMoney",
          label: "追缴金额",
          width: "",
          formatter: (row, column) => {
            if (row.recoverMoney) {
              if (row.recoverMoney >= 0) {
                return Number(row.recoverMoney / 100).toFixed(2) + "元";
              } else {
                return row.recoverMoney + "元";
              }
            } else {
              return "0.00元";
            }
          },
        },
        {
          prop: "carNum",
          label: "追缴车辆数",
          width: "",
        },
        {
          prop: "touchableCarNum",
          label: "可触达车辆数",
          width: "",
        },
        {
          prop: "touchablePercent",
          label: "预估触达率",
          width: "",
        },
        {
          prop: "touchableMoney",
          label: "可触达金额",
          width: "",
          formatter: (row, column) => {
            if (row.touchableMoney) {
              if (row.touchableMoney >= 0) {
                return Number(row.touchableMoney / 100).toFixed(2) + "元";
              } else {
                return row.touchableMoney + "元";
              }
            } else {
              return "0.00元";
            }
          },
        },
      ],
    };
  },
  created() {
    this.defalutDate = [getBeforeDate(31) + " 00:00:00", getBeforeDate() + " 23:59:59"];
    this.formInline.taskTimeStart = this.defalutDate[0];
    this.formInline.taskTimeEnd = this.defalutDate[1];
    this.searchData();
  },
  methods: {
    // 搜索
    searchData() {
      let opt = {
        method: "get",
        url: "/acb/2.0/recoverTask/list",
        data: {
          taskStatus: "3",
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.formInline,
          isAuth: 1,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.loading = true;
      this.$request(opt);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    handleCommand(command, row) {
      if (command === "1") {
        this.$router.push({
          path: "/taskAuditOperation",
          query: {
            id: row.id,
            type: row.taskType,
            detailType: "3",
            back: this.$route.path,
          },
        });
      } else if (command === "2") {
        this.$router.push({
          path: "/taskAuditOperation",
          query: {
            id: row.id,
            type: row.taskType,
            detailType: "2",
            back: this.$route.path,
          },
        });
      }
    },
    timeChange(timeArr) {
      this.formInline.taskTimeStart = timeArr[0];
      this.formInline.taskTimeEnd = timeArr[1];
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
  },
  components: {
    timeRangePick,
  },
};
</script>
<style lang="stylus" scoped></style>
